import { css, html, LitElement, nothing } from "lit";
import { createRef, ref } from "lit/directives/ref.js";
import { classMap } from "lit/directives/class-map.js";
import { close, infoCircleSolid } from "../../utils/svgProvider.js";
import strings, { updateStrings } from "../../utils/strings.js";
import { BUTTON_SIZES as TERTIARY_BUTTON_SIZES } from "../components/tertiary-button";
import { BUTTON_SIZES as SECONDARY_BUTTON_SIZES } from "../components/secondary-button";
import { VALIDATION_RULES } from "./_config.js";

import "./_stars.js";
import "./_description.js";
import "./_guidelines.js";
import "./_unsaved-prompt.js";
import "./_success.js";
import "./_form-error.js";

const MODAL_MODE = {
  FORM: "form",
  GUIDELINES: "guidelines",
  UNSAVED_PROMPT: "unsaved_prompt",
  SUCCESS: "success",
  FORM_ERROR: "form_error",
};

class AddReview extends LitElement {
  static properties = {
    _disabled: { state: true },
    _isModalOpen: { state: true },
    _modalMode: { state: true },
    _scrollWidth: { state: true },
    _scoreValue: { state: true },
    _scoreError: { state: true },
    _titleValue: { state: true },
    _titleError: { state: true },
    _descriptionValue: { state: true },
    _descriptionError: { state: true },
    _nameValue: { state: true },
    _nameError: { state: true },
    productTitle: { type: String },
    productId: { type: String },
    customerName: { type: String },
    translations: { type: Object },
  };

  scrollBlockRef = createRef();
  scoreRef = createRef();
  titleRef = createRef();
  descriptionRef = createRef();
  nameRef = createRef();

  constructor() {
    super();
    this.customerName = "";
    this._isModalOpen = false;
    this._disabled = false;
    this._modalMode = MODAL_MODE.FORM;
    this._scrollWidth = 0;
    this._resetFormState();
  }

  connectedCallback() {
    super.connectedCallback();
    updateStrings(this.translations);
  }

  updated() {
    const searchParams = new URLSearchParams(window.location.search);
    const isEditMode = searchParams.get("edit") === "true";
    const hasUserToken = searchParams.get("bvuserToken");

    if (!this._isModalOpen) {
      if ((isEditMode && this.customerName) || hasUserToken) {
        this._openModal();
      }
    }
  }

  _resetFormState() {
    this._scoreValue = 0;
    this._scoreError = "";
    this._titleValue = "";
    this._titleError = "";
    this._descriptionValue = "";
    this._descriptionError = "";
    this._nameValue = this.customerName || "";
    this._nameError = "";
  }

  _hasChanges() {
    return (
      this._scoreValue ||
      this._titleValue ||
      this._descriptionValue ||
      this._nameValue !== this.customerName
    );
  }

  _verifyUrl() {
    const url = new URL(window.location.href);

    if (url.searchParams.has("edit") || url.searchParams.has("bvuserToken")) {
      url.searchParams.delete("edit");
      url.searchParams.delete("bvuserToken");

      history.replaceState(null, "", url.href);
    }
  }

  _openModal() {
    this._resetFormState();
    this._modalMode = MODAL_MODE.FORM;
    this._isModalOpen = true;
  }

  _closeModal(confirmed = false) {
    this._verifyUrl();

    if (!confirmed && this._hasChanges()) {
      this._modalMode = MODAL_MODE.UNSAVED_PROMPT;
    } else {
      this._isModalOpen = false;
    }
  }

  _validateScore() {
    this._scoreError =
      this._scoreValue === 0
        ? strings["product.addreview.error.fieldRequired"]
        : "";
  }

  _validateTitle() {
    const titleLength = this._titleValue.length;

    if (titleLength === 0) {
      this._titleError = strings["product.addreview.error.fieldRequired"];
    } else if (titleLength > VALIDATION_RULES.title.maxLength) {
      this._titleError = `${strings["product.addreview.error.maximumCharacters"]}: ${VALIDATION_RULES.title.maxLength}`;
    } else {
      this._titleError = "";
    }
  }

  _validateDescription() {
    const descriptionLength = this._descriptionValue.length;

    if (descriptionLength === 0) {
      this._descriptionError = strings["product.addreview.error.fieldRequired"];
    } else if (descriptionLength < VALIDATION_RULES.description.minLength) {
      this._descriptionError = `${strings["product.addreview.error.minimumCharacters"]}: ${VALIDATION_RULES.description.minLength}`;
    } else if (descriptionLength > VALIDATION_RULES.description.maxLength) {
      this._descriptionError = `${strings["product.addreview.error.maximumCharacters"]}: ${VALIDATION_RULES.description.maxLength}`;
    } else {
      this._descriptionError = "";
    }
  }

  _validateName() {
    const nameLength = this._nameValue.length;

    if (nameLength === 0) {
      this._nameError = strings["product.addreview.error.fieldRequired"];
    } else if (nameLength < VALIDATION_RULES.name.minLength) {
      this._nameError = `${strings["product.addreview.error.minimumCharacters"]}: ${VALIDATION_RULES.name.minLength}`;
    } else if (nameLength > VALIDATION_RULES.name.maxLength) {
      this._nameError = `${strings["product.addreview.error.maximumCharacters"]}: ${VALIDATION_RULES.name.maxLength}`;
    } else {
      this._nameError = "";
    }
  }

  _scrollToFirstError() {
    if (this._scoreError) {
      this.scoreRef?.value?.scrollIntoView(true);
    } else if (this._titleError) {
      this.titleRef?.value?.scrollIntoView(true);
    } else if (this._descriptionError) {
      this.descriptionRef?.value?.scrollIntoView(true);
    } else if (this._nameError) {
      this.nameRef?.value?.scrollIntoView(true);
    }
  }

  _onSubmitButtonClick() {
    this._validateScore();
    this._validateTitle();
    this._validateDescription();
    this._validateName();
    this._scrollToFirstError();

    if (
      this._titleError ||
      this._scoreError ||
      this._descriptionError ||
      this._nameError
    ) {
      return;
    }

    const authToken = new URLSearchParams(window.location.search).get("bvuserToken");

    const data = {
      title: this._titleValue,
      nickname: this._nameValue,
      text: this._descriptionValue,
      rating: this._scoreValue,
      sku: this.productId,
      fingerprint: encodeURIComponent(window.IGLOO?.getBlackbox().blackbox),
      ...(authToken ? { authToken } : {}),
    };

    this._disabled = true;

    fetch("/api-product/v1/reviews/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.isSuccess) {
          this._modalMode = MODAL_MODE.SUCCESS;
        } else {
          return Promise.reject(responseData.result);
        }
      })
      .catch((error) => {
        this._modalMode = MODAL_MODE.FORM_ERROR;
        console.error("form failed with error: ", error);
      })
      .finally(() => {
        this._disabled = false;
      });
  }

  render() {
    return html`<div>
      <komplett-secondary-button
        @click=${() => {
          if (this.customerName) {
            this._openModal();
          } else {
            window.location.replace(
              `/login?redirectTo=${decodeURIComponent(
                window.location.pathname + "?edit=true#reviews"
              )}`
            );
          }
        }}
      >
        ${strings["product.addreview.openModalButton"]}
      </komplett-secondary-button>
      <komplett-modal
        class=${classMap({
          "modal-success": this._modalMode === MODAL_MODE.SUCCESS,
          "modal-form-error": this._modalMode === MODAL_MODE.FORM_ERROR,
          "modal-unsaved-prompt": this._modalMode === MODAL_MODE.UNSAVED_PROMPT,
          "modal-guidelines": this._modalMode === MODAL_MODE.GUIDELINES,
        })}
        .isOpen=${this._isModalOpen}
        .onOverlayClick=${() => {
          if (this._modalMode === MODAL_MODE.FORM) {
            this._closeModal();
          }
        }}
        .onMount=${() => {
          if (this.scrollBlockRef.value) {
            this._scrollWidth =
              this.scrollBlockRef.value.offsetWidth -
              this.scrollBlockRef.value.clientWidth;
          }
        }}
      >
        ${this._isModalOpen && this._modalMode === MODAL_MODE.FORM
          ? html`
              <div class="header">
                <komplett-h2>
                  ${strings["product.addreview.modalTitle"]}
                </komplett-h2>
                <button
                  @click=${() => this._closeModal()}
                  aria-label="Close"
                  type="button"
                  class="close-button"
                >
                  ${close}
                </button>
              </div>
              <div class="scroll-block" ${ref(this.scrollBlockRef)}>
                <form
                  id="add-review"
                  class="content"
                  style="width: calc(100% + ${this._scrollWidth}px)"
                >
                  <div class="product-preview">
                    <img
                      alt=${this.productTitle}
                      title=${this.productTitle}
                      src="/img/p/100/${this.productId}.jpg"
                    />
                    <div>${this.productTitle}</div>
                  </div>
                  <div class="obligatory-info">
                    ${strings["product.addreview.info.mandatoryField"]}
                  </div>
                  <komplett-add-review-stars
                    ${ref(this.scoreRef)}
                    ?disabled=${this._disabled}
                    value=${this._scoreValue}
                    .error=${this._scoreError}
                    .onChange=${(score) => {
                      this._scoreValue = score;
                      this._validateScore();
                    }}
                  ></komplett-add-review-stars>
                  <div class="form-group" ${ref(this.titleRef)}>
                    <komplett-label bold="true" required="true">
                      ${strings["product.addreview.title.label"]}
                    </komplett-label>
                    <komplett-input
                      ?disabled=${this._disabled}
                      value=${this._titleValue}
                      placeholder=${strings[
                        "product.addreview.title.placeholder"
                      ]}
                      .onChange=${(event) => {
                        this._titleValue = event.currentTarget.value;
                        this._validateTitle();
                      }}
                      .onFocus=${() => (this._titleError = "")}
                      .onFocusOut=${() => this._validateTitle()}
                    ></komplett-input>
                    <komplett-input-error
                      marginTop="12"
                      text=${this._titleError}
                    ></komplett-input-error>
                  </div>
                  <komplett-add-review-description
                    ${ref(this.descriptionRef)}
                    ?disabled=${this._disabled}
                    value=${this._descriptionValue}
                    .onChange=${(value) => {
                      this._descriptionValue = value;
                      this._validateDescription();
                    }}
                    .error=${this._descriptionError}
                    .onFocus=${() => (this._descriptionError = "")}
                    .onFocusOut=${() => this._validateDescription()}
                  ></komplett-add-review-description>
                  <div class="form-group" ${ref(this.nameRef)}>
                    <komplett-label bold="true" required="true">
                      ${strings["product.addreview.username.label"]}
                    </komplett-label>
                    <komplett-input
                      ?disabled=${this._disabled}
                      placeholder=${this.customerName ||
                      strings["product.addreview.username.placeholder"]}
                      value=${this._nameValue}
                      .onChange=${(event) => {
                        this._nameValue = event.currentTarget.value;
                        this._validateName();
                      }}
                      .onFocus=${() => (this._nameError = "")}
                      .onFocusOut=${() => this._validateName()}
                    ></komplett-input>
                    <komplett-input-error
                      marginTop="12"
                      text=${this._nameError}
                    ></komplett-input-error>
                  </div>
                  <div class="info-box">
                    ${infoCircleSolid}
                    <div class="info-box-text">
                      <div>
                        ${strings["product.addreview.info.guidelines.1"]}
                      </div>
                      <div>
                        <komplett-link
                          href="/"
                          @click=${(event) => {
                            event.preventDefault();
                            this._modalMode = MODAL_MODE.GUIDELINES;
                          }}
                        >
                          ${strings["product.addreview.info.guidelines.2"]}
                        </komplett-link>
                        ${strings["product.addreview.info.guidelines.3"]}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="footer">
                <komplett-tertiary-button
                  size=${TERTIARY_BUTTON_SIZES.LARGE}
                  .onClick=${() => this._closeModal()}
                >
                  ${strings["product.addreview.cancelButton"]}
                </komplett-tertiary-button>
                <komplett-secondary-button
                  ?disabled=${this._disabled}
                  size=${SECONDARY_BUTTON_SIZES.LARGE}
                  .onClick=${() => this._onSubmitButtonClick()}
                >
                  ${strings["product.addreview.publishButton"]}
                </komplett-secondary-button>
              </div>
            `
          : nothing}
        ${this._isModalOpen && this._modalMode === MODAL_MODE.UNSAVED_PROMPT
          ? html`<komplett-add-review-unsaved-prompt
              .onRejectClick=${() => (this._modalMode = MODAL_MODE.FORM)}
              .onConfirmClick=${() => this._closeModal(true)}
            ></komplett-add-review-unsaved-prompt>`
          : nothing}
        ${this._isModalOpen && this._modalMode === MODAL_MODE.SUCCESS
          ? html`<komplett-add-review-success
              .onCloseButtonClick=${() => this._closeModal(true)}
            ></komplett-add-review-success>`
          : nothing}
        ${this._isModalOpen && this._modalMode === MODAL_MODE.FORM_ERROR
          ? html`<komplett-add-review-form-error
              .onCloseButtonClick=${() => this._closeModal(true)}
            ></komplett-add-review-form-error>`
          : nothing}
        ${this._isModalOpen && this._modalMode === MODAL_MODE.GUIDELINES
          ? html`<komplett-add-review-guidelines
              .onBackButtonClick=${() => (this._modalMode = MODAL_MODE.FORM)}
            ></komplett-add-review-guidelines>`
          : nothing}
      </komplett-modal>
    </div>`;
  }

  static styles = css`
    :host {
      text-align: left;
    }

    .modal-success {
      --komplett-modal-desktop-width: 450px;
      --komplett-modal-desktop-height: fit-content;
      --komplett-modal-desktop-top: calc(50vh - 200px);
    }

    .modal-form-error {
      --komplett-modal-desktop-width: 450px;
      --komplett-modal-desktop-height: fit-content;
      --komplett-modal-desktop-top: calc(50vh - 200px);
    }

    .modal-unsaved-prompt {
      --komplett-modal-desktop-width: 450px;
      --komplett-modal-desktop-height: fit-content;
      --komplett-modal-desktop-top: calc(50vh - 150px);
    }

    @media (min-height: 860px) {
      .modal-guidelines {
        --komplett-modal-desktop-height: fit-content;
      }
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin: 32px;
      margin-bottom: 0;
    }

    .close-button {
      display: flex;
      border: 0;
      background: none;
      padding: 0;
      border-radius: 100%;
      cursor: pointer;
      outline: 2px solid transparent;
      transition: outline 0.2s ease;
    }

    .close-button:hover,
    .close-button:focus,
    .close-button:active {
      outline-color: #222222;
    }

    .close-button svg {
      width: 40px;
      height: 40px;
    }

    .scroll-block {
      margin-top: 24px;
      padding: 0 32px;
      height: calc(100% - 212px);
      overflow-y: scroll;
      overflow-x: clip;
      scroll-behavior: smooth;
    }

    .content {
      display: flex;
      flex-direction: column;
    }

    .product-preview {
      display: flex;
      align-items: center;
      height: 68px;
      margin-bottom: 12px;
    }

    .product-preview img {
      width: 90px;
      max-height: 68px;
    }

    .product-preview div {
      margin-left: 16px;
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      color: #000;
    }

    .obligatory-info {
      margin-top: 16px;
      font-size: 14px;
      line-height: 18px;
    }

    .obligatory-info::after {
      content: "*";
      color: #b41400;
    }

    .form-group {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
    }

    .info-box {
      display: flex;
      margin-top: 24px;
      padding: 16px;
      background-color: #f5f5f5;
      border-radius: 8px;
    }

    .info-box svg path {
      fill: #2a41bb;
    }

    .info-box-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 16px;
      color: #404040;
      font-size: 16px;
      line-height: 24px;
    }

    .footer {
      margin: 32px;
      display: flex;
      gap: 16px;
      justify-content: flex-end;
      align-items: center;
    }

    .hidden {
      display: none;
    }

    komplett-add-review-stars {
      margin-top: 12px;
    }

    komplett-input {
      width: 100%;
      margin-top: 12px;
      --komplett-input-width: 100%;
    }

    komplett-add-review-description {
      margin-top: 16px;
    }
  `;
}

customElements.define("komplett-add-review", AddReview);
